// Social.js
import React from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import './Berichte.css';

const Social = () => {
  return (
    <div className="berichte-container">
      <div className="info-card">
        <FaInfoCircle className="info-icon" />
        <h2>Soziale Faktoren werden bald hinzugefügt</h2>
        <p>
          Aktuell sind keine Analysefaktoren für den sozialen Bereich verfügbar. Bitte beachten Sie, dass wir am <strong>07.10.2024</strong> zusätzliche Analysefaktoren im Bereich <strong>Soziales</strong> hinzufügen werden.
        </p>
        <p>
          Der soziale Aspekt im ESG befasst sich mit Themen wie Arbeitsbedingungen, Menschenrechten, Diversität und Gemeinschaftsengagement. Diese Faktoren helfen Unternehmen, ihre sozialen Auswirkungen zu verstehen und zu verbessern.
        </p>
        <p>
          Bis dahin empfehlen wir Ihnen, weiterhin Daten hinzuzufügen und unsere Plattform zu erkunden. Bei Fragen oder Anregungen stehen wir Ihnen gerne zur Verfügung.
        </p>
      </div>
    </div>
  );
};

export default Social;
