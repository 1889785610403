// Analyse.js

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Button, Form, Alert, ListGroup, ProgressBar, Table, Dropdown, Modal } from 'react-bootstrap';
import { FaBrain, FaDownload, FaTrash, FaEye, FaInfoCircle } from 'react-icons/fa';
import axios from 'axios';
import { FaChartBar } from 'react-icons/fa';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import de from 'date-fns/locale/de';

import { parseISO, format } from 'date-fns';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { CSVLink } from 'react-csv';

import { motion } from 'framer-motion';

import html2canvas from 'html2canvas';

import { useDropzone } from 'react-dropzone';

import '../data.css';
import '../Analyse.css';

registerLocale('de', de);

const Analyse = ({ onNavigate }) => {
  // Estados y referencias
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadMessage, setUploadMessage] = useState('');
  const [fileList, setFileList] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [analysisProgress, setAnalysisProgress] = useState(0);
  const [skippedFiles, setSkippedFiles] = useState([]);
  const [analyzing, setAnalyzing] = useState(false);
  const [analysisResults, setAnalysisResults] = useState([]);

  const [start_datum, setstart_datum] = useState(null);
  const [end_datum, setend_datum] = useState(null);

  const fileInputRef = useRef(null);
  const tableRef = useRef(null);

  const [, setFilesProcessed] = useState(0);
  const [, setTotalFiles] = useState(0);

  // Nuevo estado para los factores seleccionados
  const [selectedFactors, setSelectedFactors] = useState([]);

  // Lista de factores
  const factors = [
    "benzin",
    "diesel",
    "stromverbrauch",
    "erdgas",
    "erzeugte_abfaelle",
    "kaeltemittel",
    "wasserverbrauch",
    "papierverbrauch"
  ];

  // Función para formatear fechas en el formato DD.MM.YY
  const formatDate = (dateInput) => {
    if (!dateInput || dateInput === '') return '';
    try {
      const date = parseISO(dateInput);
      if (isNaN(date)) {
        console.warn('Fecha inválida:', dateInput);
        return '';
      }
      return format(date, 'dd.MM.yy');
    } catch (error) {
      console.warn('Error al formatear la fecha:', dateInput, error);
      return '';
    }
  };

  // Función para obtener los resultados de análisis
  const fetchAnalysisResults = useCallback(async () => {
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      const response = await axios.get(`${backendUrl}/analyse/analysis-results/`);
      const { results } = response.data;
      setAnalysisResults(results);
    } catch (error) {
      console.error('Fehler beim Abrufen der Analyseergebnisse:', error);
    }
  }, []);

  // useEffect para registrar los resultados del análisis
  useEffect(() => {
    console.log('Analysis Results:', analysisResults);
  }, [analysisResults]);

  // Función para obtener la lista de archivos subidos
  const fetchFileList = async () => {
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      const response = await axios.get(`${backendUrl}/analyse/files/`);
      setFileList(response.data.files);
    } catch (error) {
      console.error('Fehler beim Abrufen der Dateiliste:', error);
    }
  };

  // Función para monitorear el progreso del análisis
  const monitorAnalysisProgress = useCallback(async () => {
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      const response = await axios.get(`${backendUrl}/analyse/analysis-status/`);
      if (response.data.analyzing) {
        const progress = (response.data.files_processed / response.data.total_files) * 100;
        setAnalysisProgress(progress);
        setFilesProcessed(response.data.files_processed);
        setTotalFiles(response.data.total_files);
        setTimeout(monitorAnalysisProgress, 1000);
      } else {
        setAnalyzing(false);
        setAnalysisProgress(0);
        setFilesProcessed(0);
        setTotalFiles(0);
      }
    } catch (error) {
      console.error('Fehler beim Überwachen des Analysefortschritts:', error);
    }
  }, []);

  // Función para verificar el estado del análisis
  const checkAnalysisStatus = useCallback(async () => {
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      const response = await axios.get(`${backendUrl}/analyse/analysis-status/`);
      if (response.data.analyzing) {
        setAnalyzing(true);
        const progress = (response.data.files_processed / response.data.total_files) * 100;
        setAnalysisProgress(progress);
        setFilesProcessed(response.data.files_processed);
        setTotalFiles(response.data.total_files);
        monitorAnalysisProgress();
      }
    } catch (error) {
      console.error('Fehler beim Überprüfen des Analysezustands:', error);
    }
  }, [monitorAnalysisProgress]);

  // Carga inicial de datos y verificación del estado de análisis
  useEffect(() => {
    fetchFileList();
    fetchAnalysisResults();
    checkAnalysisStatus();
  }, [fetchAnalysisResults, checkAnalysisStatus]);

  // Manejo de selección de archivos
  const handleFileChange = (e) => {
    setSelectedFiles(prevFiles => [...prevFiles, ...Array.from(e.target.files)]);
    setUploadProgress(0);
    setSkippedFiles([]);
    setUploadMessage('');
  };

  // Uso de react-dropzone para arrastrar y soltar
  const onDrop = useCallback((acceptedFiles) => {
    setSelectedFiles(prevFiles => [...prevFiles, ...acceptedFiles]);
    setUploadProgress(0);
    setSkippedFiles([]);
    setUploadMessage('');
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
  });

  // Subir archivos en lotes de 20
  const handleFileUpload = async () => {
    if (analyzing) {
      setUploadMessage('Während einer Analyse können keine Dateien hochgeladen werden.');
      return;
    }

    if (selectedFiles.length === 0) {
      setUploadMessage('Es wurden keine Dateien ausgewählt.');
      return;
    }

    const filesArray = Array.from(selectedFiles);
    const chunkSize = 20;
    let totalUploaded = 0;
    let totalFilesToUpload = filesArray.length;

    for (let i = 0; i < filesArray.length; i += chunkSize) {
      const chunk = filesArray.slice(i, i + chunkSize);
      const formData = new FormData();
      chunk.forEach(file => formData.append('files', file));

      try {
        const backendUrl = process.env.REACT_APP_BACKEND_URL;

        const response = await axios.post(`${backendUrl}/analyse/upload/`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        if (response.status === 200) {
          const { uploaded_files = [], skipped_files = [] } = response.data;
          let uploadMsg = '';

          if (uploaded_files.length > 0) {
            uploadMsg += 'Dateien erfolgreich hochgeladen. ';
          }

          if (skipped_files.length > 0) {
            uploadMsg += `${skipped_files.length} Datei(en) wurden übersprungen, da sie bereits vorhanden waren.`;
            setSkippedFiles(skipped_files);
          } else {
            setSkippedFiles([]);
          }

          setUploadMessage(uploadMsg.trim());
          totalUploaded += chunk.length;

          // Actualizar el progreso
          const progress = (totalUploaded / totalFilesToUpload) * 100;
          setUploadProgress(progress);

          await fetchFileList();
          await fetchAnalysisResults();
        }
      } catch (error) {
        setUploadMessage('Fehler beim Hochladen der Dateien.');
        console.error('Fehler beim Hochladen der Dateien:', error);
      }
    }

    setUploadProgress(0);
    setSelectedFiles([]);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  // Iniciar el análisis y actualizar datos
  const handleAnalyzeAndUpdate = useCallback(async () => {
    if (analyzing) {
      setUploadMessage('Es läuft bereits eine Analyse.');
      return;
    }

    setAnalyzing(true);
    setAnalysisProgress(0);

    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      // Obtener la lista de archivos para calcular el progreso
      const filesResponse = await axios.get(`${backendUrl}/analyse/files/`);
      const totalFilesToProcess = filesResponse.data.files.length;
      setTotalFiles(totalFilesToProcess);

      if (totalFilesToProcess === 0) {
        setUploadMessage('Es gibt keine Dateien, die auf eine Analyse warten.');
        setAnalyzing(false);
        return;
      }

      // Enviar la solicitud al backend
      const response = await fetch(`${backendUrl}/analyse/analyze-update`, {
        method: 'POST',
      });

      if (!response.ok) {
        throw new Error('Fehler bei der Analyseanforderung.');
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        const chunk = decoder.decode(value);
        const lines = chunk.split('\n\n');
        for (let line of lines) {
          if (line.startsWith('data: ')) {
            const data = JSON.parse(line.replace('data: ', ''));

            if (data.status === 'complete') {
              setAnalyzing(false);
              break;
            }

            if (data.status === 'error') {
              console.error(`Fehler in der Datei ${data.file}: ${data.message}`);
            }

            setFilesProcessed(data.files_processed);
            const progress = (data.files_processed / data.total_files) * 100;
            setAnalysisProgress(progress);

            // Actualizar los archivos pendientes
            setFileList(prevFiles => prevFiles.filter(file => file !== data.file));

            // Actualizar los resultados de análisis
            fetchAnalysisResults();

            if (progress >= 100) {
              setAnalyzing(false);
            }
          }
        }
      }
    } catch (error) {
      console.error('Fehler während der Analyse und Aktualisierung:', error);
      setAnalyzing(false);
      setUploadMessage('Es ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es später erneut.');
    }
  }, [analyzing, fetchAnalysisResults]);

  // Función para obtener la fecha para ordenar
  const getSortDate = (result) => {
    if (result.date && result.date !== '') {
      return new Date(result.date);
    } else if (result.start_date && result.start_date !== '') {
      return new Date(result.start_date);
    } else {
      return new Date(0);
    }
  };

  // Resultados ordenados y filtrados
  const sortedResults = [...analysisResults].sort((a, b) => getSortDate(a) - getSortDate(b));

  const filteredResults = sortedResults.filter((result) => {
    let resultDate = null;
    if (result.date && result.date !== '') {
      resultDate = new Date(result.date);
    } else if (result.start_date && result.start_date !== '') {
      resultDate = new Date(result.start_date);
    }

    let isAfterStart = true;
    let isBeforeEnd = true;

    if (start_datum && resultDate) {
      isAfterStart = resultDate >= start_datum;
    }

    if (end_datum && resultDate) {
      isBeforeEnd = resultDate <= end_datum;
    }

    const isFactorSelected = selectedFactors.length > 0 ? selectedFactors.includes(result.factor) : true;
    return isAfterStart && isBeforeEnd && isFactorSelected;
  });

  // Datos para la exportación CSV
  const csvData = filteredResults.map(({ date, start_date, end_date, factor, value, co2_aequivalent, bemerkungen }) => ({
    Datum: date && date !== '' ? formatDate(date) : (start_date && start_date !== '' && end_date && end_date !== '' ? `${formatDate(start_date)} - ${formatDate(end_date)}` : ''),
    Faktor: factor,
    Wert: value,
    'CO₂-Äquivalent': co2_aequivalent.toFixed(4),
    Bemerkungen: bemerkungen,
  }));

  // Función para exportar la tabla como imagen
  const exportTableAsImage = async () => {
    if (tableRef.current) {
      try {
        const canvas = await html2canvas(tableRef.current);
        const imgData = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = imgData;
        link.download = 'analyse_ergebnisse.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('Fehler beim Exportieren der Tabelle als Bild:', error);
      }
    }
  };

  // Función para eliminar un resultado de análisis
  const handleDeleteResult = async (id) => {
    if (window.confirm('Sind Sie sicher, dass Sie dieses Ergebnis löschen möchten?')) {
      try {
        const backendUrl = process.env.REACT_APP_BACKEND_URL;
        await axios.delete(`${backendUrl}/analyse/analysis-results/${id}/`);
        // Actualizar los resultados de análisis
        await fetchAnalysisResults();
      } catch (error) {
        console.error('Fehler beim Löschen des Ergebnisses:', error);
        alert('Fehler beim Löschen des Ergebnisses. Bitte versuchen Sie es erneut.');
      }
    }
  };

  // Función para eliminar un archivo pendiente
  const handleDeletePendingFile = async (filename) => {
    if (window.confirm(`Sind Sie sicher, dass Sie die Datei "${filename}" löschen möchten?`)) {
      try {
        const backendUrl = process.env.REACT_APP_BACKEND_URL;
        await axios.delete(`${backendUrl}/analyse/files/${encodeURIComponent(filename)}/`);
        // Actualizar la lista de archivos
        await fetchFileList();
      } catch (error) {
        console.error('Fehler beim Löschen der Datei:', error);
        alert('Fehler beim Löschen der Datei. Bitte versuchen Sie es erneut.');
      }
    }
  };

  // Estados para el modal de Datei anzeigen (mostrar archivo)
  const [showFileBanner, setShowFileBanner] = useState(false);
  const [fileContent, setFileContent] = useState('');
  const [fileName, setFileName] = useState('');

  // Función para cerrar el modal y limpiar la URL del objeto
  const handleCloseModal = () => {
    setShowFileBanner(false);
    if (fileContent) {
      URL.revokeObjectURL(fileContent);
      setFileContent('');
    }
  };

  // Función para abrir el archivo en un modal
  const handleOpenFile = async (filename) => {
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      const response = await axios.get(`${backendUrl}/analyse/get-file/${encodeURIComponent(filename)}`, {
        responseType: 'blob',
      });

      // Crear una URL a partir del Blob
      const fileURL = URL.createObjectURL(response.data);
      setFileContent(fileURL);
      setFileName(filename);
      setShowFileBanner(true);
    } catch (error) {
      console.error('Fehler beim Abrufen der Datei:', error);
      alert('Fehler beim Abrufen der Datei. Bitte versuchen Sie es erneut.');
    }
  };

  // Función para descargar un archivo
  const handleDownloadFile = async (filename) => {
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      const response = await axios.get(`${backendUrl}/analyse/get-file/${encodeURIComponent(filename)}`, {
        responseType: 'blob',
      });

      // Crear un enlace de descarga
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Fehler beim Herunterladen der Datei:', error);
      alert('Fehler beim Herunterladen der Datei. Bitte versuchen Sie es erneut.');
    }
  };

  // Nuevos estados para el modal de bemerkungen
  const [showBemerkungenModal, setShowBemerkungenModal] = useState(false);
  const [selectedBemerkungen, setSelectedBemerkungen] = useState('');

  // Funciones para manejar el modal de bemerkungen
  const handleShowBemerkungen = (bemerkungenText) => {
    setSelectedBemerkungen(bemerkungenText);
    setShowBemerkungenModal(true);
  };

  const handleCloseBemerkungenModal = () => {
    setShowBemerkungenModal(false);
    setSelectedBemerkungen('');
  };

  return (
    <motion.div
      className='analyse-container'
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      {/* Contenido */}

      <div className="content-wrapper">
        {/* Sección para subir y analizar */}
        <section className="mb-5">
          {analyzing ? (
            <Alert variant="warning">
              Eine Analyse ist im Gange. Sie können derzeit keine Dateien hochladen.
            </Alert>
          ) : (
            <>
              {/* Nuevo mensaje para el usuario */}
              <div className="card special-card">
                <div className="card-body">
                  <FaChartBar className="card-icon" />
                  <h5 className="card-title mb-2">Dateien hochladen und analysieren</h5>
                  <p className="card-text">
                    Hier können Sie Ihre Daten hochladen, und die von LIKS entwickelte künstliche Intelligenz wird die benötigten Daten extrahieren, um Ihren CO₂-Fußabdruck zu auditieren und mehr.
                  </p>
                </div>
              </div>

              {/* Formulario de subida */}
              <Form>
                <Form.Group controlId="fileInput" className="mb-3">
                  <Form.Control type="file" multiple onChange={handleFileChange} ref={fileInputRef} disabled={analyzing} />
                </Form.Group>

                {/* Área de arrastrar y soltar */}
                <div
                  {...getRootProps()}
                  className={`dropzone ${isDragActive ? 'active' : ''}`}
                >
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <p>Dateien hier ablegen...</p>
                  ) : (
                    <p>Ziehen Sie Dateien hierher, um sie hochzuladen</p>
                  )}
                </div>

                {/* Mostrar archivos seleccionados */}
                {selectedFiles.length > 0 && (
                  <div className="mt-3">
                    <h5>Ausgewählte Dateien:</h5>
                    <ListGroup variant="flush">
                      {Array.from(selectedFiles).map((file, index) => (
                        <ListGroup.Item key={index} className='file-name-small'>{file.name}</ListGroup.Item>
                      ))}
                    </ListGroup>
                  </div>
                )}

                <div className="d-flex justify-content-between mt-3">
                  <Button onClick={handleFileUpload} className="btn-upload" disabled={analyzing}>Dateien hochladen</Button>
                  <Button
                    onClick={handleAnalyzeAndUpdate}
                    className="btn-ai"
                    disabled={analyzing}
                  >
                    <FaBrain className="me-2" />
                    {analyzing ? 'Analyse läuft...' : 'Daten analysieren und aktualisieren (KI)'}
                  </Button>
                </div>
              </Form>
            </>
          )}

          {/* Barra de progreso para la subida */}
          {uploadProgress > 0 && (
            <div className="mt-3">
              <ProgressBar animated now={uploadProgress} label={`${Math.round(uploadProgress)}%`} />
            </div>
          )}

          {uploadMessage && <Alert variant="info" className="mt-3">{uploadMessage}</Alert>}

          {skippedFiles.length > 0 && (
            <Alert variant="warning" className="mt-3">
              Die folgenden Dateien wurden übersprungen, da sie bereits vorhanden sind: {skippedFiles.join(', ')}
            </Alert>
          )}

          {/* Barra de progreso para el análisis */}
          {analyzing && (
            <div className="mt-3">
              <ProgressBar animated now={analysisProgress} label={`Analyse ${Math.round(analysisProgress)}%`} />
             
            </div>
          )}

          <div className="mt-4">
            <h5>Dateien, die auf Analyse warten:</h5>
            <ListGroup variant="flush">
              {fileList.length > 0 ? (
                fileList.map((file, index) => (
                  <ListGroup.Item key={index} className='file-name-small d-flex justify-content-between align-items-center'>
                    {file}
                    <Button
                      variant="outline-danger"
                      size="sm"
                      onClick={() => handleDeletePendingFile(file)}
                      className="btn-delete"
                    >
                      <FaTrash />
                    </Button>
                  </ListGroup.Item>
                ))
              ) : (
                <ListGroup.Item>Keine Dateien warten auf Analyse.</ListGroup.Item>
              )}
            </ListGroup>
          </div>
        </section>

        {/* Resultados de análisis */}
        <section>
          {/* Filtros de fecha y factor */}
          <div className="date-filters mb-3 d-flex align-items-center flex-wrap">
            <div className="me-3 mb-2">
              <DatePicker
                selected={start_datum}
                onChange={(date) => setstart_datum(date)}
                dateFormat="dd.MM.yyyy"
                locale="de"
                className="form-control"
                placeholderText="Startdatum auswählen"
              />
            </div>
            <div className="me-3 mb-2">
              <DatePicker
                selected={end_datum}
                onChange={(date) => setend_datum(date)}
                dateFormat="dd.MM.yyyy"
                locale="de"
                className="form-control"
                placeholderText="Enddatum auswählen"
                minDate={start_datum}
              />
            </div>
            {/* Filtro de factores */}
            <div className="me-3 mb-2" style={{ minWidth: '200px' }}>
              <Autocomplete
                multiple
                options={factors}
                getOptionLabel={(option) => option}
                value={selectedFactors}
                onChange={(event, newValue) => {
                  setSelectedFactors(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Filter nach Faktoren"
                    placeholder="Faktoren auswählen"
                  />
                )}
              />
            </div>
            {/* Opciones de exportación */}
            <div className="ms-auto mb-2">
              <Dropdown>
                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                  <FaDownload className="me-2" /> Daten exportieren
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <CSVLink data={csvData} filename={"analyse_ergebnisse.csv"} className="dropdown-item">
                    Als CSV exportieren
                  </CSVLink>
                  <Dropdown.Item onClick={exportTableAsImage}>Als Bild exportieren</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          {/* Tabla de resultados de análisis */}
          {filteredResults.length > 0 ? (
            <motion.div
              className="table-responsive"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <Table hover responsive className="analysis-table" ref={tableRef}>
                <thead>
                  <tr>
                    <th>Datum</th>
                    <th>Faktor</th>
                    <th>Wert</th>
                    <th>CO₂-Äquivalent</th>
                    <th className="bemerkungen-column">Bemerkungen</th>
                    <th>Aktion</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredResults.map((result, index) => (
                    <tr key={index} className="align-middle">
                      <td>
                        {result.date && result.date !== '' ? formatDate(result.date)
                          : (result.start_date && result.start_date !== '' && result.end_date && result.end_date !== ''
                            ? `${formatDate(result.start_date)} - ${formatDate(result.end_date)}`
                            : '')}
                      </td>
                      <td>{result.factor}</td>
                      <td>{result.value}</td>
                      <td>{result.co2_aequivalent.toFixed(4)}</td>
                      {/* Modificación de la columna 'Bemerkungen' */}
                      <td className="bemerkungen-column">
                        {result.bemerkungen && result.bemerkungen.trim() !== '' ? (
                          <Button
                            variant="outline-info"
                            size="sm"
                            onClick={() => handleShowBemerkungen(result.bemerkungen)}
                            className="btn-action"
                          >
                            <FaInfoCircle />
                          </Button>
                        ) : null}
                      </td>
                      <td>
                        <Button
                          variant="outline-primary"
                          size="sm"
                          onClick={() => handleOpenFile(result.filename)}
                          className="me-2 btn-action"
                        >
                          <FaEye />
                        </Button>
                        <Button
                          variant="outline-success"
                          size="sm"
                          onClick={() => handleDownloadFile(result.filename)}
                          className="me-2 btn-action"
                        >
                          <FaDownload />
                        </Button>
                        {/* Eliminamos el botón de información de esta columna */}
                        <Button
                          variant="outline-danger"
                          size="sm"
                          onClick={() => handleDeleteResult(result.id)}
                          className="btn-action"
                        >
                          <FaTrash />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </motion.div>
          ) : (
            <p>Keine Daten für den ausgewählten Datumsbereich verfügbar.</p>
          )}
        </section>
      </div>

      {/* Modal para mostrar el archivo */}
      <Modal show={showFileBanner} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: '16px' }}>{fileName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Usar un iframe para mostrar el PDF */}
          <iframe src={fileContent} width="100%" height="600px" title="PDF Viewer"></iframe>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Schließen
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal para mostrar bemerkungen */}
      <Modal show={showBemerkungenModal} onHide={handleCloseBemerkungenModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Bemerkungen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{selectedBemerkungen}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseBemerkungenModal}>
            Schließen
          </Button>
        </Modal.Footer>
      </Modal>
    </motion.div>
  );
};

export default Analyse;
